import React, { useContext, useEffect, useMemo } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { getAccessTokenProps } from 'services/MSALService'
import _ from 'lodash'
import clsx from 'clsx'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { allCustomerOption, iCustomerList, useCustomerList } from 'data/CustomerListHook'
import {
  allBusinessUnitOption,
  useBusinessUnitsListWrapper2,
} from 'data/BusinessUnitsListHookWrapper'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { iBusinessUnitDetails } from 'data/Interfaces'
import { iOperatorInfo, useOperatorList } from 'data/OperatorListHook'
import {
  addDevice,
  iAddDeviceDTO,
  iDeviceAssignmentDTO,
  useGetDeviceTypes,
} from 'pages/devices-2/data/AddDeviceData'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { DatePickerControl } from 'components/formComponents'
import SelectControl2 from 'components/formComponents/SelectControl2'
import SelectControl3 from 'components/formComponents/SelectControl3'
import TextInputControl from 'components/formComponents/TextInputControl'
import {
  AlertIcon,
  CloseIcon,
  DeclineIcon,
  Spinner,
  TickIcon,
  TickIconWithCircle,
} from 'assets/icons'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { AxiosError } from 'axios'
import { BusinessContext } from 'contexts/BusinessContext'
import { deviceMutationKey } from 'services/apis/caching/device'
import { returnStaticDate } from 'components/modules/Calender/CalenderUtils'
import { CreateDeviceFormType } from '../CreateDeviceFormType'
import { DeviceTypeListWithImage } from '../CreateDeviceFormComponent'
import { DeviceSummary } from './DeviceSummary'

export interface CreateDeviceFormProps {
  onClose: () => void

  // formValues?: CreateDeviceFormType
  // nextFormStep: () => void
  // setDeviceData: (deviceData: CreateDeviceFormType) => void
  // redirectPageURL: string
  // isGlobalUser: boolean
  // userInfo: iAuthenticatedUserInfo
}
const defaultFormValues: CreateDeviceFormType = {
  SerialNumber: '',
  AssetNumber: '',
  DeviceType: null,
  Customer: null,
  BusinessUnit: null,
  DeviceUser: null,
  CalibrationDue: null,
  LastCalibratedOn: null,
}
const MaxFormSteps = 3

function AddDeviceForm(props: CreateDeviceFormProps) {
  const {
    customerDataFromQuery,
    selectedCustomer: globalSelectedCustomer,
    selectedBusinessUnit: globalSelectedBusinessUnit,
  } = useContext(BusinessContext)

  const { onClose } = props
  const [formStep, setFormStep] = React.useState<number>(0)
  const [disableAddBtn, setDisableAddBtn] = React.useState<boolean>(true)
  const nextStep = () => {
    if (formStep < MaxFormSteps - 1) {
      setFormStep((currentStep) => currentStep + 1)
    }
  }
  // const prevFormStep = () => {
  //   if (formStep > 0) {
  //     setFormStep((currentStep) => currentStep - 1)
  //   }
  // }
  const { genericEventHandler } = useGenericEventHandler()
  const queryClient = useQueryClient()
  const msalContext = useMsal()
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const userAccount = useContext(UserAccountContext)
  const { state: userInfo } = userAccount
  const isGlobalUser = IsUserGlobalScope(userInfo)

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    register,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useForm<CreateDeviceFormType>({
    defaultValues: defaultFormValues,
    criteriaMode: 'all',
    shouldFocusError: true,
    mode: 'all',
  })
  const formWatch = watch()
  const addDeviceMutation = useMutation({
    mutationKey: deviceMutationKey(getValues('SerialNumber')),
    mutationFn: (deviceInfo: CreateDeviceFormType) => {
      const CalibrationDue = deviceInfo?.CalibrationDue
        ? returnStaticDate(deviceInfo?.CalibrationDue as Date, true)
        : ''
      const LastCalibratedOn = deviceInfo?.LastCalibratedOn
        ? returnStaticDate(deviceInfo?.LastCalibratedOn as Date, true)
        : ''

      let custId = ''
      if (isGlobalUser) {
        custId = deviceInfo.Customer?.id ?? ''
      } else {
        custId = userInfo?.user?.customerId ?? ''
      }

      const deviceToAdd: iAddDeviceDTO = {
        deviceTypeId: deviceInfo.DeviceType?.value as string,
        customerId: custId,
        businessUnitId: deviceInfo.BusinessUnit?.id ?? '',
        serialNumber: deviceInfo.SerialNumber,
        customerAssetNumber: deviceInfo.AssetNumber,
        Note: '',
      }
      if (deviceInfo.CalibrationDue) {
        deviceToAdd.calibrationDueDate = CalibrationDue
      }
      if (deviceInfo.LastCalibratedOn) {
        deviceToAdd.serviceDate = LastCalibratedOn
      }
      if (deviceInfo.DeviceUser) {
        const deviceAssignment: iDeviceAssignmentDTO = {
          deviceUserId: deviceInfo.DeviceUser?.id ?? '',
          assignedOn: new Date().toISOString(),
        }
        deviceToAdd.deviceAssignment = deviceAssignment
      }

      const accessTokenProps: getAccessTokenProps = {
        msalContext,
        redirectPageURL,
      }

      const mutationResult = addDevice({ deviceToAdd, accessTokenProps })
      return mutationResult
    },
    onSuccess: async () => {
      setFormStep(2)
      queryClient.invalidateQueries({
        queryKey: ['devices'],
      })
    },
    onError: (error: AxiosError) => {
      console.log('Error adding device', error)
    },
  })

  const {
    isLoading: isAddDeviceMutationLoading,
    isError: isAddDeviceMutationError,
    error: addDeviceMutationError,
    isSuccess: isAddDeviceMutationSuccess,
  } = addDeviceMutation

  const [customerListData, setCustomerListData] = React.useState<iCustomerList[]>([])
  const [businessUnitsListData, setBusinessUnitsListData] = React.useState<iBusinessUnitDetails[]>(
    [],
  )
  const [deviceTypesWithImageList, setDeviceTypesWithImageList] = React.useState<
    IselectOptionProps[]
  >([])

  const {
    data: customerList,
    isLoading: isCustomerListLoading,
    isError: isCustomerListError,
    error: customerListError,
  } = useCustomerList(redirectPageURL, isGlobalUser, userInfo?.user?.id !== '' && isGlobalUser)

  const {
    data: businessUnitsList,
    isLoading: isBusinessUnitsListLoading,
    isError: isBusinessUnitsListError,
    error: businessUnitsListError,
  } = useBusinessUnitsListWrapper2(
    formWatch.Customer?.id ?? '',
    redirectPageURL,
    isGlobalUser,
    !!userInfo?.user?.id && !!formWatch.Customer?.id && !isCustomerListLoading,
  )

  const selectedCustomer = useMemo(
    () =>
      globalSelectedCustomer && globalSelectedCustomer?.id !== 'all'
        ? globalSelectedCustomer
        : customerDataFromQuery?.data?.[1],
    [globalSelectedCustomer, customerDataFromQuery?.data?.[1]],
  )

  const selectedBusinessUnit = useMemo(
    () =>
      globalSelectedBusinessUnit && globalSelectedBusinessUnit?.id !== 'all'
        ? globalSelectedBusinessUnit
        : businessUnitsList?.[1],
    [globalSelectedBusinessUnit, businessUnitsList?.[1]],
  )

  const {
    data: operatorList,
    isLoading: isOperatorListLoading,
    isError: isOperatorListError,
    error: operatorListError,
  } = useOperatorList(
    redirectPageURL,
    formWatch.BusinessUnit?.id ?? '',
    formWatch.Customer?.id ?? '',
    businessUnitsListData,
    userInfo?.user?.id !== '' &&
      Boolean(isGlobalUser ? formWatch.Customer?.id : userInfo.user.customerId) &&
      formWatch.BusinessUnit?.id !== '' &&
      isGlobalUser
      ? !isCustomerListLoading
      : true && !isBusinessUnitsListLoading,
  )

  const {
    data: deviceTypesList,
    // isLoading: isDeviceTypesListLoading,
    isError: isDeviceTypesListError,
    error: deviceTypesListError,
  } = useGetDeviceTypes(redirectPageURL)

  useEffect(() => {
    if (customerList) {
      const customerListModified = [...customerList]
      _.remove(customerListModified, (c) => c.id === allCustomerOption.id)
      setCustomerListData(customerListModified)

      if (selectedCustomer?.id === '') {
        setValue('Customer', customerListModified[0])
      } else {
        const selCustomer = customerListModified.find((c) => c.id === selectedCustomer?.id)
        if (selCustomer) {
          setValue('Customer', selCustomer)
        }
      }
    }
  }, [customerList, selectedCustomer])

  useEffect(() => {
    switch (true) {
      case isCustomerListError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: customerListError?.message || 'Error getting customer list',
          error: customerListError,
          extraData: {
            component: 'CreateDeviceForm',
            action: 'get customer list',
          },
        })
        break
      case isBusinessUnitsListError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: businessUnitsListError?.message || 'Error getting business units list',
          error: businessUnitsListError,
          extraData: {
            component: 'CreateDeviceForm',
            action: 'get business units list',
          },
        })

        break
      case isOperatorListError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: operatorListError?.message || 'Error getting operator list',
          error: operatorListError,
          extraData: {
            component: 'CreateDeviceForm',
            action: 'get operator list',
          },
        })
        break
      case isDeviceTypesListError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: deviceTypesListError?.message || 'Error getting device types list',
          error: deviceTypesListError,
          extraData: {
            component: 'CreateDeviceForm',
            action: 'get device types list',
          },
        })
        break
      case isAddDeviceMutationError:
        genericEventHandler({
          type: 'Error',
          severity: 'error',
          message: addDeviceMutationError?.message || 'Error adding device',
          error: addDeviceMutationError,
          extraData: {
            component: 'CreateDeviceForm',
            action: 'add device',
          },
        })
        break
      default:
        break
    }
  }, [
    isDeviceTypesListError,
    isBusinessUnitsListError,
    isOperatorListError,
    isCustomerListError,
    isAddDeviceMutationError,
    businessUnitsListError,
    operatorListError,
    customerListError,
    deviceTypesListError,
    addDeviceMutationError,
  ])

  useEffect(() => {
    if (businessUnitsList) {
      const businessUnitsListModified = [...businessUnitsList]
      _.remove(businessUnitsListModified, (c) => c.id === allBusinessUnitOption.id)
      setBusinessUnitsListData(businessUnitsListModified)
      if (selectedBusinessUnit?.id === '') {
        setValue('BusinessUnit', businessUnitsListModified[0], {
          shouldValidate: true,
          shouldDirty: true,
        })
      } else {
        const selBU = businessUnitsListModified.find((c) => c.id === selectedBusinessUnit?.id)
        if (selBU) {
          setValue('BusinessUnit', selBU, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      }
    }
  }, [businessUnitsList])

  useEffect(() => {
    if (deviceTypesList) {
      const deviceTypesWithImages = DeviceTypeListWithImage({ deviceTypes: deviceTypesList })
      setDeviceTypesWithImageList(deviceTypesWithImages)
    }
  }, [deviceTypesList])

  const onError = () =>
    // formErrors: FieldErrors<CreateDeviceFormType>
    {
      console.log('Error..')
    }

  const onSubmit: SubmitHandler<CreateDeviceFormType> = (deviceInfo: CreateDeviceFormType) => {
    addDeviceMutation.mutate(deviceInfo)
  }

  return (
    <div className='font-poppins px-6 pt-18 pb-10 w-full '>
      <div className='flex flex-col '>
        <div id='add-device-form-header' className='flex flex-row justify-center items-center mb-6'>
          <div className='w-full font-bold text-base leading-5'>Add device</div>
          <div
            title='Close'
            role='button'
            tabIndex={0}
            onClick={() => onClose()}
            onKeyDown={() => onClose()}
            className='flex justify-center items-center bg-c-dark-blue-1 rounded-full p-2'
          >
            <CloseIcon toggleClick={() => onClose()} className='w-6 h-6 ' />
          </div>
        </div>

        <div id='add-device-form'>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit, onError)}
            className='w-full'
            autoComplete='off'
          >
            <div id='-device-form-elements-buttons' className='flex flex-col'>
              {formStep === 0 && (
                <div id='add-device-form-elements'>
                  <div id='add-device-form-elements' className='flex flex-col gap-y-2 items-start'>
                    <div id='add-device-sr-device-type' className='flex flex-row w-full gap-2'>
                      <div className='flex flex-col gap-0.5 items-start w-full'>
                        <label
                          htmlFor='SerialNumber'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Serial Number
                        </label>
                        <Controller
                          control={control}
                          {...register('SerialNumber', {
                            required: 'Serial number is required',
                            minLength: {
                              value: 3,
                              message: 'Serial number must be at least 3 characters long',
                            },
                            maxLength: {
                              value: 15,
                              message: 'Serial number must be at most 15 characters long',
                            },
                            pattern: {
                              value: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9-]*$/,
                              message: 'Serial number must be alphanumeric',
                            },
                          })}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id='serialNumber'
                              elementProps={{
                                value: field.value,
                                defaultValue: field.value,
                                placeholder: 'Serial number',
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                  field.onChange(event.target.value.trim().replaceAll(' ', ''))
                                },
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className='flex flex-col gap-0.5 items-start w-full'>
                        <label
                          htmlFor='deviceTypeFilter'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Device Type
                        </label>

                        <Controller
                          {...register('DeviceType', {
                            required: 'Device type is required',
                            validate: {
                              isDeviceTypeSelected: (deviceType) =>
                                (deviceType?.value !== null && deviceType?.value !== '') ||
                                'Please select device type',
                            },
                          })}
                          control={control}
                          render={({ field }) => (
                            <SelectControl3
                              className='w-full h-[42px]'
                              selectControlProps={{
                                options: deviceTypesWithImageList,
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: true,
                                openMenuOnClick: true,
                                placeholder: 'Device type',
                                isClearable: false,
                                onChange: (selectedOption: IselectOptionProps) => {
                                  field.onChange(selectedOption)
                                },
                                onBlur: () => {
                                  // console.log('blur')
                                  field.onBlur()
                                  // validate the field
                                  // triggerValidation('DeviceType')
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-2 gap-0.5 w-full items-start self-stretch'>
                      <p className='text-xs text-red-500'>{errors.SerialNumber?.message}</p>
                      <p className='text-xs text-red-500'>{errors.DeviceType?.message}</p>
                    </div>

                    <div className='flex flex-col gap-0.5 items-start self-stretch'>
                      <label
                        htmlFor='assetNumber'
                        className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                      >
                        Asset Number
                      </label>
                      <Controller
                        name='AssetNumber'
                        control={control}
                        render={({ field }) => (
                          <TextInputControl
                            className='w-full h-[42px]'
                            id='assetNumber'
                            elementProps={{
                              value: field.value,
                              defaultValue: field.value,
                              placeholder: 'Asset number',
                              onChange: (selectedOption: string) => {
                                field.onChange(selectedOption)
                              },
                            }}
                          />
                        )}
                      />
                      <p className='text-xs text-red-500'>{errors.AssetNumber?.message}</p>
                    </div>

                    {isGlobalUser && (
                      <div className='flex flex-col gap-0.5 items-start self-stretch'>
                        <label
                          htmlFor='customer'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Customer
                        </label>

                        <Controller
                          control={control}
                          {...register('Customer', {
                            required: 'Customer is required',
                          })}
                          render={({ field }) => (
                            <SelectControl2
                              className='w-full h-[42px]'
                              selectControlProps={{
                                isLoading: isCustomerListLoading,
                                options: customerListData,
                                getOptionLabel: (option: iCustomerList) => option.name,
                                getOptionvalue: (option: iCustomerList) => option.id,
                                value: field.value,
                                // customerListData && selectedCustomer !== ''
                                //   ? customerListData?.find((c) => c.id === selectedCustomer)
                                //   : customerListData?.[0],
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: true,
                                openMenuOnClick: true,
                                placeholder: 'Select customer',
                                isClearable: false,
                                onChange: (selectedOption: iCustomerList) => {
                                  field.onChange(selectedOption)
                                  // setSelectedCustomer(selectedOption.id)
                                },
                                // onChange: (selectedOption: iCustomerList) => {
                                //   field.onChange(selectedOption)
                                // },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.Customer?.message}</p>
                      </div>
                    )}

                    <div className='flex flex-col gap-0.5 items-start self-stretch'>
                      <label
                        htmlFor='businessunit'
                        className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1'
                      >
                        Business Unit
                      </label>
                      <Controller
                        control={control}
                        {...register('BusinessUnit', {
                          required: 'Business unit is required',
                        })}
                        render={({ field }) => (
                          <SelectControl2
                            className='w-full h-[42px]'
                            selectControlProps={{
                              options: businessUnitsListData,
                              isLoading: isBusinessUnitsListLoading,
                              getOptionLabel: (option: iBusinessUnitDetails) => option.name,
                              getOptionvalue: (option: iBusinessUnitDetails) => option.id,
                              value: field.value,
                              // businessUnitsListData && selectedMainBU !== ''
                              //   ? businessUnitsListData?.find((c) => c.id === selectedMainBU)
                              //   : businessUnitsListData?.[0],
                              defaultValue: field.value,
                              isMulti: false,
                              isSearchable: true,
                              isDropDownSelectable: true,
                              openMenuOnClick: true,
                              placeholder: 'Select business unit',
                              isClearable: false,
                              onChange: (selectedOption: iBusinessUnitDetails) => {
                                field.onChange(selectedOption)
                                // setSelectedMainBU(selectedOption.id)
                              },
                            }}
                          />
                        )}
                      />
                      <p className='text-xs text-red-500'>{errors.BusinessUnit?.message}</p>
                    </div>

                    <div className='flex flex-col gap-0.5 items-start self-stretch'>
                      <label
                        className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1'
                        htmlFor='assignedTo'
                      >
                        Assigned to
                      </label>
                      <Controller
                        name='DeviceUser'
                        control={control}
                        render={({ field }) => (
                          <SelectControl2
                            className='w-full h-[42px]'
                            selectControlProps={{
                              options: operatorList || [],
                              isLoading: isOperatorListLoading,
                              getOptionLabel: (option: iOperatorInfo) =>
                                `${option?.firstName} ${option?.lastName}`,
                              getOptionvalue: (option: iOperatorInfo) => option?.id,
                              // value: operatorList?.[0],
                              value: field.value,
                              defaultValue: field.value,
                              isMulti: false,
                              isSearchable: true,
                              openMenuOnClick: true,
                              placeholder: 'Select operator',
                              isClearable: true,
                              onChange: (selectedOption: iOperatorInfo) => {
                                field.onChange(selectedOption)
                              },
                            }}
                          />
                        )}
                      />
                      <p className='text-xs text-red-500'>{errors.DeviceUser?.message}</p>
                    </div>

                    <div
                      id='cal-dates'
                      className='flex flex-row justify-between gap-x-2 items-center '
                    >
                      {/* <div className='w-[110px]'> */}
                      <div className='flex flex-col gap-0.5 items-start w-full'>
                        <div>
                          <label
                            htmlFor='servdate'
                            className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                          >
                            Service date
                          </label>
                        </div>
                        <div className='h-[42px]'>
                          <Controller
                            name='LastCalibratedOn'
                            control={control}
                            rules={{
                              validate: {
                                lastCalNotInFuture: (lastCalDate) =>
                                  (lastCalDate && lastCalDate <= new Date()) ||
                                  lastCalDate === null ||
                                  'Service date must be today or in the past',
                              },
                            }}
                            render={({ field }) => (
                              <DatePickerControl
                                className=''
                                datePickerProps={{
                                  dayClassName: (date: Date) => {
                                    const activeDate = 'text-c-dark-blue-1 select-none'
                                    const inActiveDate = 'text-c-blue select-none'
                                    return Date.now() <= date.getTime() ? inActiveDate : activeDate
                                  },
                                  maxDate: new Date(),
                                  selected: field.value,
                                  onChange: (date: Date | null) => field.onChange(date as Date),
                                  onBlur: () => {
                                    field.onBlur()
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                        {/* </div> */}
                      </div>

                      {/* <div className='w-[110px]'> */}
                      <div className='flex flex-col gap-0.5 items-start w-full'>
                        <label
                          htmlFor='caldue'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Calibration date
                        </label>
                        <div className=''>
                          <Controller
                            name='CalibrationDue'
                            control={control}
                            rules={{
                              validate: {
                                calDueMaxOneYear: (calDate) =>
                                  (calDate &&
                                    calDate <=
                                      new Date(
                                        new Date().setFullYear(new Date().getFullYear() + 1),
                                      )) ||
                                  calDate === null ||
                                  'Calibration due date must be within one year',
                              },
                            }}
                            render={({ field }) => (
                              <DatePickerControl
                                className=''
                                datePickerProps={{
                                  minDate: new Date(),
                                  dayClassName: (date: Date) => {
                                    const activeDate = 'text-c-dark-blue-1 select-none'
                                    const inActiveDate = 'text-c-blue select-none'
                                    return Date.now() >= date.getTime() ? inActiveDate : activeDate
                                  },
                                  selected: field.value,
                                  onChange: (date: Date | null) => field.onChange(date as Date),
                                  onBlur: () => {
                                    field.onBlur()
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                    <p className='text-xs text-red-500'>{errors.CalibrationDue?.message}</p>
                    <p className='text-xs text-red-500'>{errors.LastCalibratedOn?.message}</p>
                  </div>
                </div>
              )}
              {formStep === 1 && (
                <div>
                  <div className='font-poppins text-base font-bold'>
                    Confirm this is the correct device
                  </div>
                  <DeviceSummary deviceInfo={getValues()} />
                  <div className='w-full flex flex-row gap-x-2 mt-7'>
                    <button
                      type='button'
                      onClick={() => setDisableAddBtn(false)}
                      // disabled={disableAddBtn}
                      className={clsx(
                        'rounded-3xl w-[92px] h-12 border-3  hover:border-c-dark-blue-1  focus:border-c-blue ',
                        disableAddBtn
                          ? 'border-c-light-blue-3 bg-c-white text-c-dark-blue-4'
                          : 'text-c-white bg-c-blue border-c-blue',
                      )}
                    >
                      <div className='flex flex-row  gap-x-2 justify-center px-3'>
                        <TickIconWithCircle
                          className={clsx('w=5')}
                          fill={disableAddBtn ? '#BDBDBD' : '#FFFFFF'}
                        />
                        <div className='text-xs font-bold leading-5 '>YES</div>
                      </div>
                    </button>

                    <button
                      type='button'
                      className={clsx(
                        'w-full h-12 px-3 rounded-3xl text-base font-bold leading-5 border-3 border-c-light-blue-3 bg-c-light-blue-2 text-c-dark-blue-1 outline-0  focus:bg-c-dark-blue-4 focus:text-c-white  hover:border-c-dark-blue-1  active:bg-c-dark-blue-3 active:text-c-white',
                      )}
                      onClick={() => {
                        setFormStep(0)
                        setDisableAddBtn(true)
                      }}
                    >
                      <div className='flex flex-row  gap-x-2 justify-center items-center px-3'>
                        <DeclineIcon className='w-5 fill-c-light-blue-4 hover:fill-c-light-blue focus:fill-c-blue active:fill-c-white' />
                        <div className='text-xs font-bold text-c-dark-blue-4 active:text-c-white'>
                          NO, CHANGE DETAILS
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              )}
              {formStep === 2 && (
                <div>
                  <div className='font-poppins text-base font-bold'>Device added successfully</div>
                  <DeviceSummary deviceInfo={getValues()} />
                </div>
              )}

              {/* Common Form Buttons */}
              <div id='form-buttons' className='w-full flex flex-row justify-between gap-x-2 mt-10'>
                {formStep !== 2 && (
                  <button
                    type='button'
                    className={clsx(
                      'w-28 h-[52px] px-5 rounded-3xl text-base font-bold leading-5 bg-c-light-blue-2 text-c-dark-blue-1 outline-0  focus:bg-c-dark-blue-4 focus:text-c-white  hover:bg-c-dark-blue-2 hover:text-c-white  active:bg-c-dark-blue-3 active:text-c-white disabled:bg-c-light-blue-2 disabled:text-c-dark-blue-1',
                    )}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </button>
                )}
                {formStep === 0 ? (
                  <button
                    type='button'
                    disabled={!isValid || !isDirty}
                    className={clsx(
                      'w-full h-[52px] px-5 rounded-3xl text-base font-bold leading-5 bg-c-dark-blue-1 text-c-white outline-0  focus:bg-c-dark-blue-4 focus:text-c-white  hover:bg-c-dark-blue-2 hover:text-c-white  active:bg-c-dark-blue-3 active:text-c-white disabled:bg-c-light-blue-2 disabled:text-c-dark-blue-1',
                    )}
                    onClick={() => nextStep()}
                  >
                    Next
                  </button>
                ) : formStep === 1 ? (
                  <div className='flex-grow'>
                    <button
                      type='submit'
                      className={clsx(
                        'w-full h-[52px] px-5 rounded-3xl  bg-c-dark-blue-1  outline-0  focus:bg-c-dark-blue-4   hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3  disabled:bg-c-light-blue-2 disabled:text-c-dark-blue-1',
                        disableAddBtn
                          ? 'text-c-dark-blue-1 bg-c-light-blue-2'
                          : 'text-c-white bg-c-dark-blue-1 ',
                      )}
                      disabled={
                        !isDirty ||
                        !isValid ||
                        isSubmitting ||
                        isAddDeviceMutationLoading ||
                        disableAddBtn
                      }
                    >
                      <div className='px-3 md:px-5 flex flex-row  gap-1 justify-center items-center'>
                        {isAddDeviceMutationLoading && <Spinner className='w-5 h-5' />}
                        {isAddDeviceMutationSuccess && <TickIcon className='w-5 h-5' />}
                        {isAddDeviceMutationError && <AlertIcon className='fill-white w-5 h-5 ' />}
                        <div
                          className={clsx(
                            'flex-grow text-base font-bold leading-5 ',
                            disableAddBtn ? 'text-c-dark-blue-1' : 'text-c-white ',
                          )}
                        >
                          Add Device
                        </div>
                      </div>
                    </button>
                  </div>
                ) : (
                  <div className='flex-grow'>
                    <button
                      type='button'
                      className={clsx(
                        'w-full h-[42px] px-5 rounded-3xl text-base font-bold leading-5 bg-c-light-blue-2 text-c-dark-blue-1 outline-0  focus:bg-c-dark-blue-4 focus:text-c-white  hover:bg-c-dark-blue-2 hover:text-c-white  active:bg-c-dark-blue-3 active:text-c-white disabled:bg-c-light-blue-2 disabled:text-c-dark-blue-1',
                      )}
                      onClick={() => onClose()}
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>
              {isAddDeviceMutationError && (
                <div className='text-red-500 text-xs font-normal pt-2 pl-2'>
                  {addDeviceMutationError?.message ?? ''}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      <DevTool control={control} />
    </div>
  )
}

export default AddDeviceForm

// const defaultFormValues: CreateDeviceFormType = useMemo(
//   () => ({
//     SerialNumber: '',
//     AssetNumber: '',
//     DeviceType: deviceTypesWithImageList?.[0],
//     Customer:
//       customerListData && selectedCustomer !== ''
//         ? customerListData?.find((c) => c.id === selectedCustomer) ?? customerListData?.[0]
//         : customerListData?.[0],
//     BusinessUnit:
//       businessUnitsListData && selectedMainBU !== ''
//         ? businessUnitsListData?.find((c) => c.id === selectedMainBU) ??
//           businessUnitsListData?.[0]
//         : businessUnitsListData?.[0],
//     DeviceUser: operatorList?.[0],
//     CalibrationDue: null,
//     LastCalibratedOn: null,
//   }),
//   [deviceTypesWithImageList, customerListData, businessUnitsListData, operatorList],
// )

// const defaultFormValues: CreateDeviceFormType = {
//     SerialNumber: '',
//     AssetNumber: '',
//     DeviceType: deviceTypesWithImageList?.[0],
//     Customer: customerListData?.[0],
//     BusinessUnit: businessUnitsListData?.[0],
//     DeviceUser: operatorList?.[0],
//     CalibrationDue: null,
//     LastCalibratedOn: null,
//   }
